/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Icon } from '@sagacarmi/seminovos-design-system/core';
import * as Style from './styles';
import { ReactComponent as Home } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/home.svg';
import { ReactComponent as ArrowUp } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/general/arrow-left.svg';
import { ReactComponent as RefreshCcw } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/refresh-ccw.svg';
import Link from '../Link';
import { useSop } from 'context/SopContext';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const [scrollUpDisabled, setScrollUpDisabled] = React.useState(true);
  const { handleChangeStep, steps, handleRemoveFilter, currentFilters }: any =
    useSop();

  const location = useLocation();
  const [scrollDownDisabled, setScrollDownDisabled] = React.useState(false);

  const scrollBot = (e) => {
    window.scroll({ top: window.pageYOffset + 250, behavior: 'smooth' });
  };

  const scrollTop = (e) => {
    window.scroll({ top: window.pageYOffset - 250, behavior: 'smooth' });
  };

  const reloadPage = () => {
    document.location.reload();
  };

  const returnPrevPage = () => {
    if (location.pathname !== '/ofertas') {
      window.history.back();
    }

    if (steps === 'ModelYearsStep') {
      Object.values(currentFilters)?.map((currentFilter: any) => {
        if (currentFilter?.sopKey === 'mdi_') {
          handleRemoveFilter(currentFilter?.sopKey);
        }
      });
      handleChangeStep('ModelsStep');
    }

    if (steps === 'ModelsStep') {
      Object.values(currentFilters)?.map((currentFilter: any) => {
        if (currentFilter?.sopKey === 'mki_') {
          handleRemoveFilter(currentFilter?.sopKey);
        }
      });

      handleChangeStep('MakesStep');
    }

    if (steps === 'MakesStep') {
      window.history.back();
    }
  };

  const handleScroll = (scrollUpDisabled, scrollDownDisabled) => {
    if (window.pageYOffset === 0) {
      if (!scrollUpDisabled) {
        setScrollUpDisabled(true);
        return;
      }
    } else {
      if (scrollUpDisabled) {
        setScrollUpDisabled(false);
        return;
      }
    }

    const MAX_SCROLL_Y = document.body.offsetHeight - window.innerHeight;

    //IF PAGESCROLL IS EQUAL OR MORE TO MAX SCROLL Y
    if (window.pageYOffset >= MAX_SCROLL_Y) {
      if (!scrollDownDisabled) {
        setScrollDownDisabled(true);
        return;
      }
    } else {
      if (scrollDownDisabled) {
        setScrollDownDisabled(false);
        return;
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', () =>
      handleScroll(scrollUpDisabled, scrollDownDisabled),
    );

    return () => {
      window.removeEventListener('scroll', () =>
        handleScroll(scrollUpDisabled, scrollDownDisabled),
      );
    };
  }, [scrollUpDisabled, scrollDownDisabled]);

  return (
    <Style.FooterWrapper>
      <Style.FooterContentWrapper>
        <Style.FooterButton onClick={returnPrevPage}>
          <Icon icon={ArrowLeft} fontSize={'32px'} />
          Voltar
        </Style.FooterButton>
        <Style.FooterButton onClick={reloadPage}>
          <Icon icon={RefreshCcw} fontSize={'32px'} />
          Atualizar
        </Style.FooterButton>
      </Style.FooterContentWrapper>
      <Style.FooterContentWrapper>
        <Link route="/">
          <Style.FooterButton>
            <Icon icon={Home} fontSize={'32px'} />
            Início
          </Style.FooterButton>
        </Link>
      </Style.FooterContentWrapper>
      <Style.FooterContentWrapper>
        <Style.FooterButton
          onClick={(e) => scrollBot(e)}
          disabled={scrollDownDisabled}
        >
          <Icon icon={ArrowDown} fontSize={'32px'} />
          Descer
        </Style.FooterButton>
        <Style.FooterButton
          onClick={(e) => scrollTop(e)}
          disabled={scrollUpDisabled}
        >
          <Icon icon={ArrowUp} fontSize={'32px'} />
          Subir
        </Style.FooterButton>
      </Style.FooterContentWrapper>
    </Style.FooterWrapper>
  );
};

export default Footer;
