import { styled } from '@material-ui/styles';
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme';
import ButtonBase from '@material-ui/core/ButtonBase';

export const FooterWrapper = styled('footer')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.neutral.black.white1,
    boxShadow: '0px -2px 1px #E0E0E0',
    padding: '24px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 10,
  }),
);

export const FooterButton = styled(ButtonBase)(
  ({ theme: { palette } }: StyledThemeProp) => ({
    color: palette.brand.primary.main,
    background: palette.neutral.black.white1,
    border: `2px solid ${palette.brand.primary.main}`,
    borderRadius: '4px',
    padding: '16px 32px',
    fontSize: '32px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    minWidth: '220px',
    '&:disabled': {
      color: palette.neutral.grey.gray4,
      border: `2px solid ${palette.neutral.grey.gray4}`,
      '& path': {
        fill: palette.neutral.grey.gray4,
      },
    },
  }),
);

export const FooterContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
});
