import { lazy } from 'react';
import Layout from 'layout/Layout';

// PAGES
const Home = lazy(() => import('pages/Home'));
const Offers = lazy(() => import('pages/Offers'));
const Details = lazy(() => import('pages/Details'));
const Categories = lazy(() => import('pages/Categories'));

const OFFERS_PATH = '/ofertas';

const homeRoute = [
  {
    path: '/',
    element: <Home />,
  },
];

const offersRoute = [
  {
    path: OFFERS_PATH + '/seminovos',
    element: <Offers />,
  },
  {
    path: OFFERS_PATH,
    element: <Categories />,
  },
  {
    path: OFFERS_PATH + '/seminovos/:dealId',
    element: <Details />,
  },
];

const autoLayoutRoutes = [
  {
    path: OFFERS_PATH,
    element: <Layout />,
    children: [...offersRoute],
  },
];

const routes = [...homeRoute, ...autoLayoutRoutes];

export default routes;
