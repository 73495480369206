import React, { Suspense, useEffect } from 'react';
import {
  defaultTheme,
  DSThemeProvider,
} from '@sagacarmi/seminovos-design-system/core/theme';
import { BrowserRouter, useRoutes, useSearchParams } from 'react-router-dom';
import routes from 'config/routes';
import LoadingScreen from 'components/atoms/LoadingScreen/LoadingScreen';
import 'global/GlobalStyles.css';
import SopContext from 'context/SopContext';

function App() {
  return (
    <>
      <DSThemeProvider theme={defaultTheme}>
        <div className="App">
          <BrowserRouter>
            <SopContext>
              <Suspense fallback={<LoadingScreen />}>
                <Routers />
              </Suspense>
            </SopContext>
          </BrowserRouter>
        </div>
      </DSThemeProvider>
    </>
  );
}

function Routers() {
  const element = useRoutes([...routes]);

  const [searchParams] = useSearchParams();

  const setTotemType = () => {
    const queryParamTotemType = searchParams.get('totemType');
    if (['CLEAN', 'TOTEM', 'TOTEM_AFTER_SALE'].includes(queryParamTotemType)) {
      if (queryParamTotemType === 'CLEAN') {
        localStorage.removeItem('totemType');
        return;
      }
      localStorage.setItem('totemType', queryParamTotemType);
    }
  };

  const setMobiId = () => {
    const queryParamTotemType = searchParams.get('mobiId');
    if (queryParamTotemType === 'CLEAN') {
      localStorage.removeItem('mobiId');
      return;
    }
    localStorage.setItem('mobiId', queryParamTotemType);
  };

  useEffect(() => {
    setTotemType();
    setMobiId();
  }, []);

  return element;
}

export default App;
