import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import images from 'constants/images';

const LoadingScreen = () => {
  const { LogoSvg } = images;
  return (
    <Box
      component="div"
      width="100%"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gridGap="24px"
    >
      <CircularProgress />
      <LogoSvg />
    </Box>
  );
};

export default LoadingScreen;
