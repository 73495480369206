import request from 'helpers/request';
import getEnvVarByName from 'helpers/getEnvVarByName';

const domain = getEnvVarByName('PORTAL_PUBLIC_API');

export default class SearchServices {
  static makesSearch = (name: string) => {
    return request
      .get(`${domain}/search/v1.0/makes/open-search?name=${name}`, {})
      .then((response) => response);
  };

  static modelsSearch = (name: string) => {
    return request
      .get(`${domain}/search/v1.0/models/open-search?name=${name}`, {})
      .then((response) => response);
  };

  static getSortTypes = () => {
    return request
      .get(`${domain}/deal/v1.0/sort-types`, {})
      .then((response) => response);
  };
}
