import { makeStyles, styled } from '@material-ui/styles';
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme';
import TextField from '@material-ui/core/TextField';

export const SearchInputWrapper = styled('div')({
  width: '100%',
  position: 'relative',
});

export const useStyles = makeStyles({
  root: {
    borderRadius: 40,
    fontFamily: 'Aeonik',
    height: 64,
  },
  input: {
    padding: 8,
  },
  adornedStart: {
    paddingLeft: 28,
  },
});

export const InputSearch = styled(TextField)(
  ({ theme: { palette } }: StyledThemeProp) => ({
    backgroundColor: palette.neutral.black.white1,
    overflow: 'hidden',
    outline: 'none',
    fontFamily: 'Aeonik',
  }),
);

export const SearchValuesWrapper = styled('ul')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    position: 'absolute',
    background: palette.neutral.black.white1,
    borderRadius: '0px 0px 20px 20px',
    border: `1px solid ${palette.neutral.grey.gray4}`,
    listStyle: 'none',
    width: '100%',
    top: 64,
    zIndex: 10,
    padding: 0,
    maxHeight: '400px',
    overflowY: 'scroll',
  }),
);

export const SearchLoadingCenter = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
});

export const SearchTag = styled('div')(
  ({ theme: { typography } }: StyledThemeProp) => ({
    ...typography.body.desktop.bold2,
    padding: '6px 12px',
    fontSize: '1.2rem',
  }),
);

export const SearchValuesItem = styled('li')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    cursor: 'pointer',
    padding: '8px 16px',
    textDecoration: 'none',
    width: '100%',
    color: palette.neutral.black.black2,
    margin: 0,
    transition: 'ease .3s',
    '&:hover': {
      backgroundColor: palette.neutral.black.white2,
    },
  }),
);
