import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

interface LinkTypes {
  route?: string;
  children: any;
  href?: string;
}

const LinkComponent = ({ route, href, children }: LinkTypes) => {
  return (
    <Link component={RouterLink} to={route} href={href}>
      {children}
    </Link>
  );
};

export default LinkComponent;
